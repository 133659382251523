var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{attrs:{"name":"bidding","rules":{
    required: _vm.requiredField,
  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.title,"label-for":"bidding"}},[_c('vue-autosuggest',{class:errors.length > 0 ? 'error-select' : '',attrs:{"no-filter":"","suggestions":_vm.biddingsOptions,"input-props":{
        id: 'bidding',
        ref: 'bidding',
        class: errors.length > 0 ? 'form-control is-invalid' : 'form-control',
        placeholder: _vm.title,
        readonly: _vm.readonly,
      },"limit":_vm.limitElements,"get-suggestion-value":_vm.getBiddingSelected},on:{"input":_vm.onInputBiddingChange,"selected":_vm.onSelectedBidding,"blur":_vm.onBlur},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var suggestion = ref.suggestion;
return [_c('span',[_c('b',[_vm._v("UASG:")]),_vm._v(" "+_vm._s(suggestion.item.uasg))]),_c('br'),_c('span',[_c('b',[_vm._v("Nº pregão:")]),_vm._v(" "+_vm._s(suggestion.item.trade_number))]),_c('br'),_c('span',[_c('b',[_vm._v("Órgão:")]),_vm._v(" "+_vm._s(suggestion.item.client_set.name))])]}}],null,true),model:{value:(_vm.biddingSearch),callback:function ($$v) {_vm.biddingSearch=$$v},expression:"biddingSearch"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }