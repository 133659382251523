var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{attrs:{"name":"documentType","rules":{
    required: _vm.requiredField,
  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tipo do Documento","label-for":"documentType"}},[_c('v-select',{class:errors.length > 0 ? 'error-select' : '',attrs:{"id":"documentType","filter":_vm.fuseSearch,"label":"name","options":_vm.dataOptions,"placeholder":'Tipo do Documento',"disabled":_vm.readonly,"clearable":!_vm.readonly},on:{"open":function($event){return _vm.onCreate()}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
  var name = ref.name;
return [_c('span',[_vm._v(_vm._s(name))])]}},{key:"selected-option",fn:function(ref){
  var name = ref.name;
return [_c('span',[_vm._v(_vm._s(name))])]}},{key:"no-options",fn:function(){return [_vm._v(" Não há opções de Tipo do Documento. ")]},proxy:true}],null,true),model:{value:(_vm.documentType),callback:function ($$v) {_vm.documentType=$$v},expression:"documentType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }