<template>
  <validation-provider
    #default="{ errors }"
    name="contractStatus"
    :rules="{
      required: requiredField,
    }"
  >
    <b-form-group
      label="Status do Contrato"
      label-for="contractStatus"
    >
      <v-select
        id="contractStatus"
        v-model="status"
        :class="errors.length > 0 ? 'error-select' : ''"
        :filter="fuseSearch"
        label="name"
        :options="dataOptions"
        :placeholder="'Status do Contrato'"
        :disabled="readonly"
        :clearable="!readonly"
        @open="onCreate()"
      >
        <template #option="{ name, color }">
          <b-badge
            pill
            class="text-capitalize"
            :style="{'background-color': color, 'min-width':'110px'}"
          >
            {{ name | truncate(18, '...') }}
          </b-badge>
        </template>

        <template #selected-option="{ name, color }">
          <b-badge
            pill
            class="text-capitalize"
            :style="{'background-color': color, 'min-width':'110px'}"
          >
            {{ name | truncate(18, '...') }}
          </b-badge>
        </template>

        <template #no-options>
          Não há opções de status do contrato.
        </template>
      </v-select>
      <small class="text-danger">{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>
<script>
import {
  BFormGroup,
  BBadge,
} from 'bootstrap-vue'

import {
  ValidationProvider,
} from 'vee-validate'

import {
  required,
} from '@validations'

import vSelect from 'vue-select'
import Fuse from 'fuse.js'
import syslic from '@/syslic'
import truncate from '@/components/utils/filters/truncate'

export default {
  components: {
    BFormGroup,
    BBadge,
    ValidationProvider,
    vSelect,
  },
  filters: {
    truncate,
  },
  model: {
    prop: 'statusValue',
    event: 'statusChange',
  },
  props: {
    statusValue: {
      type: [Object, String],
      required: false,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    const dataOptions = []
    const newStatusValue = this.statusValue

    return {
      dataOptions,
      required,
      newStatusValue,
    }
  },
  computed: {
    status: {
      get() {
        return this.newStatusValue
      },
      set(value) {
        this.newStatusValue = value
        this.$emit('statusChange', this.newStatusValue)
      },
    },
  },
  watch: {
    statusValue(newVal) {
      this.newStatusValue = newVal
    },
  },
  created() {
    this.onCreate()
  },
  methods: {
    onCreate() {
      syslic
        .contract
        .status
        .fetchAllStatus()
        .then(response => {
          this.dataOptions = response.data.results
          this.$emit('ready', true)
        })
        .catch(() => {
          this.$emit('ready', true)
        })
    },
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ['name'],
        shouldSort: true,
      })
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style lang="scss" scoped>
.v-select {
  background-color: #fff;
}
</style>
