<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <b-table
        v-if="visibleFields.length !== 0"
        class="position-relative contractTableView"
        primary-key="number"
        show-empty
        responsive
        striped
        hover
        empty-text="Não há contratos cadastrados."
        :items="contracts"
        :fields="visibleFields"
        :busy="isLoading"
        :sort-by.sync="sortOptions.sortBy"
        :sort-desc.sync="sortOptions.isSortDirDesc"
        tbody-tr-class="contractTableCustomHeight"
        @row-clicked="onRowSelectedForDetail"
      >
        <template #table-busy>
          <div class="text-center my-3">
            <b-spinner
              variant="info"
              class="align-middle mb-1"
            />
            <br>
            <strong class="pt-2">Carregando...</strong>
          </div>

        </template>

        <template #cell(id)="field">
          <span class="font-weight-bold text-nowrap">
            # {{ field.item.id }}
          </span>
        </template>

        <template #cell(is_outdated)="field">
          <div
            v-if="field.item.is_outdated"
            class="d-block text-nowrap bg-red"
          >
            <b-badge
              pill
              class="text-capitalize isOutdatedBackground px-1 d-inline-flex align-items-center justify-content-center"
            >
              SIM
            </b-badge>
          </div>
          <div
            v-else
            class="d-block text-nowrap"
          >
            <b-badge
              pill
              class="text-capitalize isNotOutdatedBackground px-1 d-inline-flex align-items-center justify-content-center"
            >
              NÃO
            </b-badge>
          </div>
        </template>

        <template #cell(client_set.name)="field">
          <span class="font-weight-bold text-nowrap">
            {{ field.item.client_set.name }}
          </span>
        </template>

        <template #cell(company_set.name_fantasy)="field">
          <span class="font-weight-bold text-nowrap">
            {{ field.item.company_set.name_fantasy }}
          </span>
        </template>

        <template #cell(type_set.name)="field">
          <span class="font-weight-bold text-nowrap">
            {{ field.item.type_set.name }}
          </span>
        </template>

        <template #cell(number)="field">
          <span class="text-nowrap">
            {{ field.item.number || '-' }}
          </span>
        </template>

        <template #cell(date_start)="field">
          <span class="text-nowrap">
            {{ formatDate(field.item.date_start) }}
          </span>
        </template>

        <template #cell(date_finish)="field">
          <span class="text-nowrap">
            {{ formatDate(field.item.date_finish) }}
          </span>
        </template>

        <template #cell(status_set.name)="field">
          <div
            class="d-block text-nowrap"
          >
            <b-badge
              pill
              class="text-capitalize sizeMin px-1 d-inline-flex align-items-center justify-content-center"
              :style="{'background-color': field.item.status_set.color}"
            >
              {{ field.item.status_set.name }}
            </b-badge>
          </div>
        </template>

        <template #cell(actions)="field">
          <div
            :class="totalItems > 1 ? '' : 'dropdownfix'"
          >
            <b-dropdown
              variant="link"
              no-caret
              :dropup="true"
              :boundary="'scrollParent'"
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-if="field.item.state === 'draft'"
                :disabled="!hasPermissionToUpdate"
                @click="onRowSelectedForEdit(field.item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Editar</span>
              </b-dropdown-item>
              <b-dropdown-item
                :disabled="!hasPermissionToDelete"
                @click="showConfirmOrCancelDelete(field.item)"
              >
                <svg-icon
                  type="mdi"
                  size="18"
                  :path="mdiTrashCanOutline"
                />
                <span class="align-middle ml-50">Remover</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-if="visibleFields.length !== 0"
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="filter.page_size"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BPagination,
  VBTooltip,
  BBadge,
  BSpinner,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'

import {
  useToast,
} from 'vue-toastification/composition'

import {
  mdiTrashCanOutline,
} from '@mdi/js'

import moment from 'moment'
import SvgIcon from '@jamescoyle/vue-icon'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import syslic from '@/syslic'
import SyncLoad from '@/components/utils/syncLoad'
import MetaData from '@/components/utils/metaData'
import debounce from '@/components/utils/debounce'
import router from '@/router'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BBadge,
    BSpinner,
    BDropdown,
    BDropdownItem,
    SvgIcon,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  emits: ['ready'],

  data() {
    const totalItems = 0
    const currentPage = 1

    const sortOptions = {
      sortBy: 'date_finish',
      isSortDirDesc: false,
    }

    const contracts = []

    const tableColumns = [
      {
        key: 'id',
        label: 'Id',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap contractTableColumn',
      },
      {
        key: 'is_outdated',
        label: 'Contrato Defasado?',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap contractTableColumn',
      },
      {
        key: 'client_set.name',
        label: 'Órgão',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap contractTableColumn',
      },
      {
        key: 'company_set.name_fantasy',
        label: 'Empresa',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap contractTableColumn',
      },
      {
        key: 'type_set.name',
        label: 'Doc',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap contractTableColumn',
      },
      {
        key: 'number',
        label: 'Número',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap contractTableColumn',
      },
      {
        key: 'date_start',
        label: 'Início da Vigência',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap contractTableColumn',
      },
      {
        key: 'date_finish',
        label: 'Fim da Vigência',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap contractTableColumn',
      },
      {
        key: 'status_set.name',
        label: 'Status',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap contractTableColumn',
      },
      {
        key: 'actions',
        label: '',
        sortable: false,
        visible: true,
        fixedColumn: true,
        thClass: 'text-nowrap contractTableAction',
        tdClass: 'contractTableAction',
      },
    ]

    const lastSearch = ''
    const isLoading = true

    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('contracts')

    syncLoad.onReady(() => {
      this.isLoading = false
      this.$emit('ready', true)
    })

    const fetchId = ''

    const meta = new MetaData('ContractTable')

    return {
      totalItems,
      currentPage,
      tableColumns,
      lastSearch,
      syncLoad,
      isLoading,
      fetchId,
      contracts,
      sortOptions,
      meta,
    }
  },
  computed: {
    visibleFields() {
      return this.tableColumns.filter(field => field.visible)
    },
    hasPermissionToUpdate() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.contract.can_edit
    },
    hasPermissionToDelete() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.contract.can_delete
    },
  },
  watch: {
    filter: {
      handler: function updateFilter(val) {
        this.currentPage = 1
        if (this.lastSearch !== val.search) {
          this.lastSearch = val.search
        }
        this.fetchData()
      },
      deep: true,
    },
    'sortOptions.sortBy': function updateSortBy() {
      this.currentPage = 1
      this.fetchData()
    },
    'sortOptions.isSortDirDesc': function updateIsSortDirDesc() {
      this.currentPage = 1
      this.fetchData()
    },
    currentPage: function updateCurrentPage() {
      this.fetchData()
    },
  },
  created() {
    this.sortOptions = this.meta.bind(this.sortOptions, 'sortOptions')
    this.fetchData()
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiTrashCanOutline,
      moment,
    }
  },
  methods: {
    formatDate(date) {
      if (!date) {
        return '-'
      }
      const parsedDate = moment(date)

      if (parsedDate.isValid()) {
        return parsedDate.format('DD/MM/YYYY')
      }

      return '-'
    },
    refreshColumns(columns) {
      columns.forEach(element => {
        this.tableColumns.forEach((obj, i) => {
          if (obj.key === element.key) {
            this.tableColumns[i].visible = element.visible
          }
        })
      })
    },
    fetchData() {
      this.isLoading = true
      debounce.call(this.fetch)
    },
    fetch() {
      const fetchPending = Math.random()
      this.fetchId = fetchPending
      const filterContract = this.filter

      filterContract.p = this.currentPage

      if (this.sortOptions.isSortDirDesc) {
        filterContract.ordering = `-${this.sortOptions.sortBy}`
      } else {
        filterContract.ordering = this.sortOptions.sortBy
      }
      syslic
        .contract
        .fetchContractList(filterContract)
        .then(response => {
          let promise = {}

          if (fetchPending === this.fetchId) {
            this.totalItems = response.data.count
            this.contracts = response.data.results
            promise = new Promise(resolve => {
              resolve()
            })
          } else {
            promise = new Promise((resolve, reject) => {
              reject()
            })
          }

          return promise
        })
        .then(() => {
          this.syncLoad.ready('contracts')
        })
        .catch(() => {
          if (fetchPending === this.fetchId) {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Falha ao ler os dados dos contratos.',
                text: 'Não foi possível ler os dados dos contratos do servidor, por favor entre em contato com o administrador do sistema.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                timeout: false,
              },
            })
            this.syncLoad.ready('contracts')
          }
        })
    },
    deleteContract(item) {
      syslic
        .contract
        .deleteContract(item.id)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Contrato removido com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.contracts.splice(this.contracts.indexOf(item), 1)
          this.fetchData()
        })
        .catch(error => {
          let textError = 'Não foi possível remover o contrato do servidor, por favor entre em contato com o administrador do sistema.'

          if ('data' in error) {
            if (error.data.includes('they are referenced through protected foreign keys')) {
              textError = 'Este contrato é referenciado em algum pedido, por este motivo não será possível exclui-lo.'
            }
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover contrato.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    showConfirmOrCancelDelete(item) {
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme que você quer remover o contrato.', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteContract(item)
          }
        })
    },
    onRowSelectedForDetail(item) {
      router.push({ name: 'contract-detail', params: { id: item.id } }).catch(() => {})
    },
    onRowSelectedForEdit(item) {
      router.push({ name: 'contract-edit', params: { id: item.id } }).catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.contractTableColumn {
  min-width: 100px;
}

.contractTableView {
  border-radius: 5px;
}

.contractTableCustomHeight {
  height: 4.5rem;
}

.contractTableAction {
  max-width: 5rem;
}

.contractTableAction .dropdown-toggle {
  padding-left: 0px;
}

.isOutdatedBackground {
  background-color: #E51B0E;
  min-width: 110px;
}

.isNotOutdatedBackground {
  background-color: #005C96;
  min-width: 110px;
}

.sizeMin{
  min-width: 110px
}
</style>
