<template>
  <validation-provider
    #default="{ errors }"
    name="bidding"
    :rules="{
      required: requiredField,
    }"
  >
    <b-form-group
      :label="title"
      label-for="bidding"
    >
      <vue-autosuggest
        v-model="biddingSearch"
        no-filter
        :suggestions="biddingsOptions"
        :input-props="{
          id: 'bidding',
          ref: 'bidding',
          class: errors.length > 0 ? 'form-control is-invalid' : 'form-control',
          placeholder: title,
          readonly: readonly,
        }"
        :limit="limitElements"
        :class="errors.length > 0 ? 'error-select' : ''"
        :get-suggestion-value="getBiddingSelected"
        @input="onInputBiddingChange"
        @selected="onSelectedBidding"
        @blur="onBlur"
      >
        <template slot-scope="{suggestion}">
          <span><b>UASG:</b> {{ suggestion.item.uasg }}</span><br>
          <span><b>Nº pregão:</b> {{ suggestion.item.trade_number }}</span><br>
          <span><b>Órgão:</b> {{ suggestion.item.client_set.name }}</span>
        </template>
      </vue-autosuggest>
      <small class="text-danger">{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>
<script>

import {
  VueAutosuggest,
} from 'vue-autosuggest'

import {
  BFormGroup,
} from 'bootstrap-vue'

import {
  ValidationProvider,
} from 'vee-validate'

import {
  required,
} from '@validations'

import {
  useToast,
} from 'vue-toastification/composition'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import syslic from '@/syslic'

export default {
  components: {
    BFormGroup,
    VueAutosuggest,
    ValidationProvider,
  },
  model: {
    prop: 'biddingValue',
    event: 'biddingChange',
  },
  props: {
    biddingValue: {
      type: [Object, String],
      required: false,
      default: '',
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: 'Licitação',
    },
    filter: {
      type: Object,
      required: false,
      default: () => ({
        additional_address: '',
        additional_address_type: '',
        additional_address_type_set: '',
        additional_city: '',
        additional_city_set: '',
        additional_complement: '',
        additional_country: '',
        additional_country_set: '',
        additional_neighborhood: '',
        additional_neighborhood_type: '',
        additional_number: '',
        additional_state: '',
        additional_state_set: '',
        additional_zip_code: '',
        bidding_hour: '',
        city: '',
        city_set: '',
        client: '',
        client_set: '',
        company: '',
        company_set: '',
        country: '',
        country_set: '',
        crier: '',
        date: '',
        date_capture: '',
        date_clarification: '',
        date_impugnment: '',
        date_proposal: '',
        deadline: '',
        dispute: '',
        dispute_set: '',
        email: '',
        exclusive_me_epp: '',
        file_set: '',
        freight_group: '',
        freight_group_set: '',
        hour_proposal: '',
        id: '',
        interest: '',
        interest_set: '',
        is_homologated: '',
        items_set: '',
        link_support: '',
        link_trade: '',
        modality: '',
        modality_set: '',
        observation: '',
        order: '',
        owner: '',
        owner_set: '',
        payment_term: '',
        phone_number: '',
        platform: '',
        platform_set: '',
        price_registry: '',
        proposal_validity: '',
        requirements: '',
        state: '',
        state_set: '',
        status: '',
        status_set: '',
        trade_number: '',
        type: '',
        type_set: '',
        uasg: '',
        warranty_term: '',
      }),
    },
  },
  data() {
    const biddingsOptions = []
    return {
      limitElements: 20,
      biddingsOptions,
      required,
      biddingSearch: '',
    }
  },
  computed: {
    bidding: {
      get() {
        return this.biddingValue
      },
      set(value) {
        this.$emit('biddingChange', value)
      },
    },
  },
  watch: {
    biddingValue: function updateBidding(bidding) {
      if (bidding) {
        this.biddingSearch = this.getBiddingSelected({ item: bidding })
      } else {
        this.biddingSearch = ''
      }
    },
  },
  created() {
    if (this.biddingValue) {
      this.biddingSearch = this.getBiddingSelected({ item: this.biddingValue })
    }
    this.$emit('ready', true)
  },
  methods: {
    onSelectedBidding(selected) {
      if (selected === '' || selected === undefined) {
        return
      }
      this.bidding = selected.item
      this.$emit('change', selected.item)
    },
    getBiddingSelected(suggestion) {
      return `Nº Pregão: ${suggestion.item.trade_number} - ${suggestion.item.client_set.name}`
    },
    onInputBiddingChange(text) {
      const filterBidding = this.filter
      filterBidding.search = text
      filterBidding.p = 1
      filterBidding.page_size = this.limitElements

      syslic
        .bidding
        .fetchBiddingListContract(filterBidding)
        .then(response => {
          this.biddingsOptions = [{
            data: response.data.results,
          }]
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao ler os dados.',
              text: 'Não foi possível ler os dados do servidor, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    onBlur() {
      if (this.bidding) {
        const biddingSelected = this.getBiddingSelected({ item: this.bidding })
        if (this.biddingSearch !== biddingSelected) {
          this.bidding = ''
        }
      } else {
        this.biddingSearch = ''
      }
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
