<template>
  <div>
    <div v-show="isLoading">
      <spin-loader />
    </div>
    <div v-show="!isLoading">
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <contract-filter
            :visible-columns="tableColumns"
            @filter-change="updateFilter"
            @columns-change="updateColumns"
            @ready="syncLoad.ready('filter')"
          />
        </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <contract-table
            ref="refContractTable"
            :filter="filter"
            @ready="syncLoad.ready('table')"
          />
        </b-col>
      </b-row>
    </div>
    <div>
      <contract-modal
        ref="refContractModal"
        v-model="data"
        :is-add="isAdd"
        @isAdd="isAddChange"
        @save="save"
      />
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'

import {
  useToast,
} from 'vue-toastification/composition'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import syslic from '@/syslic'
import SyncLoad from '@/components/utils/syncLoad'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import ContractTable from '@/views/contract/components/ContractTable.vue'
import ContractModal from '@/views/contract/components/ContractModal.vue'
import ContractFilter from '@/views/contract/components/ContractFilter.vue'
import router from '@/router'

export default {
  components: {
    BRow,
    BCol,
    SpinLoader,
    ContractTable,
    ContractModal,
    ContractFilter,
  },
  data() {
    const filter = {
      page_size: 10,
      search: '',
    }

    const data = {
      bidding: '',
      bidding_set: '',
      date_finish: null,
      date_start: null,
      file_set: [],
      id: '',
      number: '',
      observation: '',
      owner: '',
      owner_set: '',
      state: 'draft',
      scope: '',
      scope_set: '',
      status: '',
      status_set: '',
      type: '',
      type_set: '',
    }
    const statusData = null
    const isAdd = true
    const isLoading = true
    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('table')
    syncLoad.addTrigger('status')

    syncLoad.onReady(() => {
      this.isLoading = false
    })

    const tableColumns = [
      {
        key: 'id',
        label: 'Id',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'is_outdated',
        label: 'Contrato Defasado?',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'bidding_set.client_set.name',
        label: 'Órgão',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'bidding_set.company_set.name_fantasy',
        label: 'Empresa',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'type_set.name',
        label: 'Doc',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'number',
        label: 'Número',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'date_start',
        label: 'Início da Vigência',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'date_finish',
        label: 'Fim da Vigência',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'status_set.name',
        label: 'Status',
        visible: true,
        fixedColumn: false,
      },
    ]

    return {
      isAdd,
      isLoading,
      filter,
      data,
      tableColumns,
      syncLoad,
      statusData,
    }
  },
  created() {
    this.fetchStatus()
  },
  methods: {
    updateColumns(val) {
      this.$refs.refContractTable.refreshColumns(val)
    },
    updateFilter(val) {
      this.filter = val
    },
    isAddChange(value) {
      this.isAdd = value
    },
    save() {
      if (this.isAdd) {
        if (this.statusData) {
          this.data.status = this.statusData
          this.add(this.data)
        } else {
          this.showMissingInitialStatusToast()
        }
      } else {
        this.update(this.data.id, this.data)
      }

      this.isAdd = true
    },
    add(val) {
      syslic
        .contract
        .addContract(val)
        .then(response => {
          const contract = response.data
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Contrato adicionado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.$refs.refContractTable.fetchData()
          router.push({ name: 'contract-edit', params: { id: contract.id } })
        })
        .catch(error => {
          let textError = 'Não foi possível adicionar este contrato, por favor entre em contato com o administrador do sistema.'
          let variantType = 'danger'

          if (error.data !== undefined) {
            if (error.data.includes('must make a unique set')) {
              textError = 'Já existe um contrato com o mesmo número para licitação selecionada.'
              variantType = 'warning'
            } else if (error.data.includes('Contract with this Bidding, Number and Account already exists.')) {
              textError = 'Já existe um contrato com o mesmo número para licitação selecionada.'
              variantType = 'warning'
            }
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao adicionar contrato.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: variantType,
              timeout: 5000,
            },
          })
        })
    },
    update(id, val) {
      syslic
        .contract
        .updateContract(id, val)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Contrato atualizado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.$refs.refContractTable.fetchData()
        })
        .catch(error => {
          let textError = 'Não foi possível atualizar este contrato, por favor entre em contato com o administrador do sistema.'

          if ('data' in error) {
            if (error.data.bidding[0].includes('This field must be unique.')) {
              textError = 'Já existe um contrato com a licitação selecionada.'
            }
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao atualizar contrato.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    fetchStatus() {
      syslic
        .contract
        .status
        .fetchAllStatus({
          initial: true,
        })
        .then(response => {
          if (response.data.results.length > 0) {
            this.statusData = response.data.results[0].id
            this.syncLoad.ready('status')
          } else {
            this.statusData = null
            this.syncLoad.ready('status')
          }
        })
        .catch(() => {
          this.syncLoad.ready('status')
        })
    },
    showMissingInitialStatusToast() {
      this.toast({
        component: ToastificationContent,
        props: {
          title: 'Falha ao vincular licitação.',
          text: 'Não existe status inicial para cadastro do contrato, para incluir acesse as configurações.',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      },
      { timeout: 5000 })
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
